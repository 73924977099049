import React from "react";

const InterviewGuides = () => {
    return (
        <>
            <main className="main">
                <div className="interview-guides_container__ZFxHR">
                    <div className="interview-guides_headerContainer__yfeNb">
                        <div className="interview-guides_titleContainer__wP_vF">
                            <div className="interview-guides_plugnplay__K4ysT">
                                <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                    <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                        <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27126%27%20height=%27102%27/%3e" />
                                    </span>
                                    <img alt="Plug & Play" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                    <noscript>
                                        &lt;img
                                        alt="Plug &amp;amp; Play"
                                        srcset="_next/static/media/plug-n-play.e9c59fc7.svg 1x, _next/static/media/plug-n-play.e9c59fc7.svg 2x"
                                        src="_next/static/media/plug-n-play.e9c59fc7.svg"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style="
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        box-sizing: border-box;
                                        padding: 0;
                                        border: none;
                                        margin: auto;
                                        display: block;
                                        width: 0;
                                        height: 0;
                                        min-width: 100%;
                                        max-width: 100%;
                                        min-height: 100%;
                                        max-height: 100%;
                                        "
                                        loading="lazy"
                                        /&gt;
                                    </noscript>
                                </span>
                            </div>
                            <h1 className="interview-guides_pageTitle__htL8Y">7 ready to run interview processes</h1>
                            <p className="interview-guides_pageDesc__eime_">
                                Find inspiration for improving your recruitment. Every interview process consists of a series of interview templates. Use our interview library to jump right in one of our pre-made processes
                            </p>
                        </div>
                        <div className="darkmode interview-guides_ctaContainer__zuFQL">
                            <h3 className="interview-guides_ctaSubtitle__1_Bkx">Start interviewing the right way</h3>
                            <h3 className="interview-guides_ctaTitle__epnKG">Want to use one of these interviews? We will get you started as fast as possible</h3>
                            <div>
                                <a href="https://meetings-eu1.hubspot.com/neea" target="_blank" rel="noreferrer"><button type="button" className="interview-guides_button__DLwER">Schedule a call</button></a>
                            </div>
                        </div>
                    </div>
                    <div className="interview-guides_filterContainer__oNkBC">
                        <p className="interview-guides_filterLabel__b4R5F">Filter openings</p>
                        <input className="interview-guides_input__TNTaA" defaultValue placeholder="Search..." />
                        <p className="interview-guides_filterLabel__b4R5F">Filter by Skills</p>
                        <div className="interview-guides_tagContainer__MxV_8">
                            <button className="interview-guides_tag__4YfnU">Communication</button><button className="interview-guides_tag__4YfnU">Engineering</button><button className="interview-guides_tag__4YfnU">React</button>
                            <button className="interview-guides_tag__4YfnU">Leadership</button><button className="interview-guides_tag__4YfnU">Teamwork</button><button className="interview-guides_tag__4YfnU">Customer focus</button>
                            <button className="interview-guides_tag__4YfnU">Motivation</button><button className="interview-guides_tag__4YfnU">Flexibility</button><button className="interview-guides_tag__4YfnU">Angular</button>
                            <button className="interview-guides_tag__4YfnU">Creative thinking</button><button className="interview-guides_tag__4YfnU">Decision-making</button><button className="interview-guides_tag__4YfnU">JavaScript</button>
                            <button className="interview-guides_tag__4YfnU">Planning and organizing</button><button className="interview-guides_tag__4YfnU">Content Strategy</button>
                            <button className="interview-guides_tag__4YfnU">JavaScript Frameworks</button><button className="interview-guides_tag__4YfnU">Negotiation</button>
                            <button className="interview-guides_tag__4YfnU">Analytical thinking</button><button className="interview-guides_tag__4YfnU">Diversity and inclusion</button><button className="interview-guides_tag__4YfnU">CSS</button>
                            <button className="interview-guides_tag__4YfnU">Problem-solving</button>
                        </div>
                    </div>
                    <div>
                        <ol className="interview-guides_grid__MokT2">
                            <a className="interview-guides_opening__kmi1q" href="interview-guides/yi91t7yi/interface-designer.html">
                                <div className="interview-guides_coverContainer__DdaPs" style={{ backgroundImage: 'url(_/assets/covers/cover_0.html)' }} />
                                <div className="interview-guides_contentContainer__13OC2">
                                    <h2 className="interview-guides_openingHeader__2qgJe">Interface Designer</h2>
                                    <p className="interview-guides_openingDesc__lnhrH">
                                        A two-stage interview process for assessing junior, mid-career, or senior level designers. This process includes an Interface designer interview, and a designer soft skills interview.
                                    </p>
                                    <div className="interview-guides_openingStats__tvOQM">
                                        <div className="interview-guides_openingStat__2ic16">
                                            <div>
                                                <span className="interview-guides_icon__ciEI4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path fillRule="evenodd" d="M10.431 4.65c.98 0 1.334.007 1.646.101.296.09.57.237.81.434.252.207.454.498.997 1.313l1.392 2.088c.456.684.584.892.633 1.1.05.206.05.422 0 .629-.05.207-.177.415-.633 1.1l-1.392 2.087c-.543.815-.745 1.106-.997 1.313a2.352 2.352 0 01-.81.434c-.312.094-.666.101-1.646.101H8.715l2.143-3.214.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09L8.715 4.65h1.716zm-3.278 0c-.523.003-.931.011-1.27.039-.46.037-.736.108-.95.217a2.35 2.35 0 00-1.027 1.027c-.109.214-.18.49-.217.95C3.65 7.35 3.65 7.949 3.65 8.8v2.4c0 .85 0 1.45.039 1.918.037.46.108.735.217.949a2.35 2.35 0 001.027 1.027c.214.109.49.18.95.217.339.028.747.036 1.27.038l2.623-3.934c.456-.684.584-.893.633-1.1.05-.207.05-.423 0-.63-.05-.207-.177-.415-.633-1.1L7.153 4.652zm5.302-1.143c-.52-.157-1.081-.157-1.923-.157H7.772c-.816 0-1.469 0-1.995.043-.54.044-1.006.137-1.434.355a3.65 3.65 0 00-1.595 1.595c-.218.428-.31.893-.355 1.434-.043.526-.043 1.179-.043 1.995v2.456c0 .816 0 1.469.043 1.995.044.54.137 1.006.355 1.434a3.65 3.65 0 001.595 1.595c.428.218.893.31 1.434.355.526.043 1.179.043 1.995.043h2.759c.843 0 1.404 0 1.924-.157a3.65 3.65 0 001.257-.673c.42-.345.731-.812 1.198-1.513l.056-.084 1.392-2.087.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09-1.392-2.087-.056-.084c-.467-.7-.778-1.168-1.198-1.513a3.65 3.65 0 00-1.257-.673z" clipRule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div>
                                                10{/* */}
                                                skills
                                            </div>
                                        </div>
                                        <p className="interview-guides_openingStat__2ic16">
                                            <span className="interview-guides_icon__ciEI4">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 11.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 8.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M7.21 3.993c1.004-.717 1.506-1.076 2.053-1.214a3 3 0 011.474 0c.547.138 1.05.497 2.053 1.214l2.387 1.705c.615.44.922.659 1.031.93a1 1 0 010 .743c-.109.272-.416.492-1.03.931l-2.388 1.705c-1.004.717-1.506 1.076-2.053 1.214a3 3 0 01-1.474 0c-.547-.138-1.05-.497-2.053-1.214L4.823 8.302c-.615-.44-.922-.659-1.031-.93a1 1 0 010-.743c.109-.272.416-.492 1.03-.931L7.21 3.993z" />
                                                </svg>
                                            </span>
                                            2{/* */}
                                            interviews
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a className="interview-guides_opening__kmi1q" href="interview-guides/fbgis7gd/backend-developer.html">
                                <div className="interview-guides_coverContainer__DdaPs" style={{ backgroundImage: 'url(_/assets/covers/cover_2.html)' }} />
                                <div className="interview-guides_contentContainer__13OC2">
                                    <h2 className="interview-guides_openingHeader__2qgJe">Backend Developer</h2>
                                    <p className="interview-guides_openingDesc__lnhrH">
                                        A three-stage interview process for assessing junior, mid-career, or senior level backend developer candidates. This flow includes interviews for evaluating soft skills, technical skills, as well
                                        as architecture skills.
                                    </p>
                                    <div className="interview-guides_openingStats__tvOQM">
                                        <div className="interview-guides_openingStat__2ic16">
                                            <div>
                                                <span className="interview-guides_icon__ciEI4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path fillRule="evenodd" d="M10.431 4.65c.98 0 1.334.007 1.646.101.296.09.57.237.81.434.252.207.454.498.997 1.313l1.392 2.088c.456.684.584.892.633 1.1.05.206.05.422 0 .629-.05.207-.177.415-.633 1.1l-1.392 2.087c-.543.815-.745 1.106-.997 1.313a2.352 2.352 0 01-.81.434c-.312.094-.666.101-1.646.101H8.715l2.143-3.214.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09L8.715 4.65h1.716zm-3.278 0c-.523.003-.931.011-1.27.039-.46.037-.736.108-.95.217a2.35 2.35 0 00-1.027 1.027c-.109.214-.18.49-.217.95C3.65 7.35 3.65 7.949 3.65 8.8v2.4c0 .85 0 1.45.039 1.918.037.46.108.735.217.949a2.35 2.35 0 001.027 1.027c.214.109.49.18.95.217.339.028.747.036 1.27.038l2.623-3.934c.456-.684.584-.893.633-1.1.05-.207.05-.423 0-.63-.05-.207-.177-.415-.633-1.1L7.153 4.652zm5.302-1.143c-.52-.157-1.081-.157-1.923-.157H7.772c-.816 0-1.469 0-1.995.043-.54.044-1.006.137-1.434.355a3.65 3.65 0 00-1.595 1.595c-.218.428-.31.893-.355 1.434-.043.526-.043 1.179-.043 1.995v2.456c0 .816 0 1.469.043 1.995.044.54.137 1.006.355 1.434a3.65 3.65 0 001.595 1.595c.428.218.893.31 1.434.355.526.043 1.179.043 1.995.043h2.759c.843 0 1.404 0 1.924-.157a3.65 3.65 0 001.257-.673c.42-.345.731-.812 1.198-1.513l.056-.084 1.392-2.087.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09-1.392-2.087-.056-.084c-.467-.7-.778-1.168-1.198-1.513a3.65 3.65 0 00-1.257-.673z" clipRule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div>
                                                9{/* */}
                                                skills
                                            </div>
                                        </div>
                                        <p className="interview-guides_openingStat__2ic16">
                                            <span className="interview-guides_icon__ciEI4">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 11.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 8.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M7.21 3.993c1.004-.717 1.506-1.076 2.053-1.214a3 3 0 011.474 0c.547.138 1.05.497 2.053 1.214l2.387 1.705c.615.44.922.659 1.031.93a1 1 0 010 .743c-.109.272-.416.492-1.03.931l-2.388 1.705c-1.004.717-1.506 1.076-2.053 1.214a3 3 0 01-1.474 0c-.547-.138-1.05-.497-2.053-1.214L4.823 8.302c-.615-.44-.922-.659-1.031-.93a1 1 0 010-.743c.109-.272.416-.492 1.03-.931L7.21 3.993z" />
                                                </svg>
                                            </span>
                                            3{/* */}
                                            interviews
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a className="interview-guides_opening__kmi1q" href="interview-guides/va5u96fx/react-frontend-developer.html">
                                <div className="interview-guides_coverContainer__DdaPs" style={{ backgroundImage: 'url(_/assets/covers/cover_4.html)' }} />
                                <div className="interview-guides_contentContainer__13OC2">
                                    <h2 className="interview-guides_openingHeader__2qgJe">React Frontend Developer</h2>
                                    <p className="interview-guides_openingDesc__lnhrH">
                                        A three-stage interview process for assessing junior, mid-career, or senior level frontend developer candidates with a focus on React. This flow includes interviews for evaluating soft skills,
                                        React skills, as well as architecture skills.
                                    </p>
                                    <div className="interview-guides_openingStats__tvOQM">
                                        <div className="interview-guides_openingStat__2ic16">
                                            <div>
                                                <span className="interview-guides_icon__ciEI4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path fillRule="evenodd" d="M10.431 4.65c.98 0 1.334.007 1.646.101.296.09.57.237.81.434.252.207.454.498.997 1.313l1.392 2.088c.456.684.584.892.633 1.1.05.206.05.422 0 .629-.05.207-.177.415-.633 1.1l-1.392 2.087c-.543.815-.745 1.106-.997 1.313a2.352 2.352 0 01-.81.434c-.312.094-.666.101-1.646.101H8.715l2.143-3.214.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09L8.715 4.65h1.716zm-3.278 0c-.523.003-.931.011-1.27.039-.46.037-.736.108-.95.217a2.35 2.35 0 00-1.027 1.027c-.109.214-.18.49-.217.95C3.65 7.35 3.65 7.949 3.65 8.8v2.4c0 .85 0 1.45.039 1.918.037.46.108.735.217.949a2.35 2.35 0 001.027 1.027c.214.109.49.18.95.217.339.028.747.036 1.27.038l2.623-3.934c.456-.684.584-.893.633-1.1.05-.207.05-.423 0-.63-.05-.207-.177-.415-.633-1.1L7.153 4.652zm5.302-1.143c-.52-.157-1.081-.157-1.923-.157H7.772c-.816 0-1.469 0-1.995.043-.54.044-1.006.137-1.434.355a3.65 3.65 0 00-1.595 1.595c-.218.428-.31.893-.355 1.434-.043.526-.043 1.179-.043 1.995v2.456c0 .816 0 1.469.043 1.995.044.54.137 1.006.355 1.434a3.65 3.65 0 001.595 1.595c.428.218.893.31 1.434.355.526.043 1.179.043 1.995.043h2.759c.843 0 1.404 0 1.924-.157a3.65 3.65 0 001.257-.673c.42-.345.731-.812 1.198-1.513l.056-.084 1.392-2.087.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09-1.392-2.087-.056-.084c-.467-.7-.778-1.168-1.198-1.513a3.65 3.65 0 00-1.257-.673z" clipRule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div>
                                                9{/* */}
                                                skills
                                            </div>
                                        </div>
                                        <p className="interview-guides_openingStat__2ic16">
                                            <span className="interview-guides_icon__ciEI4">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 11.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 8.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M7.21 3.993c1.004-.717 1.506-1.076 2.053-1.214a3 3 0 011.474 0c.547.138 1.05.497 2.053 1.214l2.387 1.705c.615.44.922.659 1.031.93a1 1 0 010 .743c-.109.272-.416.492-1.03.931l-2.388 1.705c-1.004.717-1.506 1.076-2.053 1.214a3 3 0 01-1.474 0c-.547-.138-1.05-.497-2.053-1.214L4.823 8.302c-.615-.44-.922-.659-1.031-.93a1 1 0 010-.743c.109-.272.416-.492 1.03-.931L7.21 3.993z" />
                                                </svg>
                                            </span>
                                            3{/* */}
                                            interviews
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a className="interview-guides_opening__kmi1q" href="interview-guides/xty91ea6/vue-frontend-developer.html">
                                <div className="interview-guides_coverContainer__DdaPs" style={{ backgroundImage: 'url(_/assets/covers/cover_1.html)' }} />
                                <div className="interview-guides_contentContainer__13OC2">
                                    <h2 className="interview-guides_openingHeader__2qgJe">Vue Frontend Developer</h2>
                                    <p className="interview-guides_openingDesc__lnhrH">
                                        A three-stage interview process for assessing junior, mid-career, or senior level frontend developer candidates with a focus on Vue. This flow includes interviews for evaluating soft skills, Vue
                                        skills, as well as architecture skills.
                                    </p>
                                    <div className="interview-guides_openingStats__tvOQM">
                                        <div className="interview-guides_openingStat__2ic16">
                                            <div>
                                                <span className="interview-guides_icon__ciEI4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path fillRule="evenodd" d="M10.431 4.65c.98 0 1.334.007 1.646.101.296.09.57.237.81.434.252.207.454.498.997 1.313l1.392 2.088c.456.684.584.892.633 1.1.05.206.05.422 0 .629-.05.207-.177.415-.633 1.1l-1.392 2.087c-.543.815-.745 1.106-.997 1.313a2.352 2.352 0 01-.81.434c-.312.094-.666.101-1.646.101H8.715l2.143-3.214.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09L8.715 4.65h1.716zm-3.278 0c-.523.003-.931.011-1.27.039-.46.037-.736.108-.95.217a2.35 2.35 0 00-1.027 1.027c-.109.214-.18.49-.217.95C3.65 7.35 3.65 7.949 3.65 8.8v2.4c0 .85 0 1.45.039 1.918.037.46.108.735.217.949a2.35 2.35 0 001.027 1.027c.214.109.49.18.95.217.339.028.747.036 1.27.038l2.623-3.934c.456-.684.584-.893.633-1.1.05-.207.05-.423 0-.63-.05-.207-.177-.415-.633-1.1L7.153 4.652zm5.302-1.143c-.52-.157-1.081-.157-1.923-.157H7.772c-.816 0-1.469 0-1.995.043-.54.044-1.006.137-1.434.355a3.65 3.65 0 00-1.595 1.595c-.218.428-.31.893-.355 1.434-.043.526-.043 1.179-.043 1.995v2.456c0 .816 0 1.469.043 1.995.044.54.137 1.006.355 1.434a3.65 3.65 0 001.595 1.595c.428.218.893.31 1.434.355.526.043 1.179.043 1.995.043h2.759c.843 0 1.404 0 1.924-.157a3.65 3.65 0 001.257-.673c.42-.345.731-.812 1.198-1.513l.056-.084 1.392-2.087.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09-1.392-2.087-.056-.084c-.467-.7-.778-1.168-1.198-1.513a3.65 3.65 0 00-1.257-.673z" clipRule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div>
                                                9{/* */}
                                                skills
                                            </div>
                                        </div>
                                        <p className="interview-guides_openingStat__2ic16">
                                            <span className="interview-guides_icon__ciEI4">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 11.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 8.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M7.21 3.993c1.004-.717 1.506-1.076 2.053-1.214a3 3 0 011.474 0c.547.138 1.05.497 2.053 1.214l2.387 1.705c.615.44.922.659 1.031.93a1 1 0 010 .743c-.109.272-.416.492-1.03.931l-2.388 1.705c-1.004.717-1.506 1.076-2.053 1.214a3 3 0 01-1.474 0c-.547-.138-1.05-.497-2.053-1.214L4.823 8.302c-.615-.44-.922-.659-1.031-.93a1 1 0 010-.743c.109-.272.416-.492 1.03-.931L7.21 3.993z" />
                                                </svg>
                                            </span>
                                            3{/* */}
                                            interviews
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a className="interview-guides_opening__kmi1q" href="interview-guides/8dirtn5c/frontend-developer.html">
                                <div className="interview-guides_coverContainer__DdaPs" style={{ backgroundImage: 'url(_/assets/covers/cover_5.html)' }} />
                                <div className="interview-guides_contentContainer__13OC2">
                                    <h2 className="interview-guides_openingHeader__2qgJe">Frontend Developer</h2>
                                    <p className="interview-guides_openingDesc__lnhrH">
                                        A three-stage interview process for assessing junior, mid-career, or senior level frontend developer candidates. This flow includes interviews for evaluating soft skills, technical skills, as well
                                        as architecture skills.
                                    </p>
                                    <div className="interview-guides_openingStats__tvOQM">
                                        <div className="interview-guides_openingStat__2ic16">
                                            <div>
                                                <span className="interview-guides_icon__ciEI4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path fillRule="evenodd" d="M10.431 4.65c.98 0 1.334.007 1.646.101.296.09.57.237.81.434.252.207.454.498.997 1.313l1.392 2.088c.456.684.584.892.633 1.1.05.206.05.422 0 .629-.05.207-.177.415-.633 1.1l-1.392 2.087c-.543.815-.745 1.106-.997 1.313a2.352 2.352 0 01-.81.434c-.312.094-.666.101-1.646.101H8.715l2.143-3.214.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09L8.715 4.65h1.716zm-3.278 0c-.523.003-.931.011-1.27.039-.46.037-.736.108-.95.217a2.35 2.35 0 00-1.027 1.027c-.109.214-.18.49-.217.95C3.65 7.35 3.65 7.949 3.65 8.8v2.4c0 .85 0 1.45.039 1.918.037.46.108.735.217.949a2.35 2.35 0 001.027 1.027c.214.109.49.18.95.217.339.028.747.036 1.27.038l2.623-3.934c.456-.684.584-.893.633-1.1.05-.207.05-.423 0-.63-.05-.207-.177-.415-.633-1.1L7.153 4.652zm5.302-1.143c-.52-.157-1.081-.157-1.923-.157H7.772c-.816 0-1.469 0-1.995.043-.54.044-1.006.137-1.434.355a3.65 3.65 0 00-1.595 1.595c-.218.428-.31.893-.355 1.434-.043.526-.043 1.179-.043 1.995v2.456c0 .816 0 1.469.043 1.995.044.54.137 1.006.355 1.434a3.65 3.65 0 001.595 1.595c.428.218.893.31 1.434.355.526.043 1.179.043 1.995.043h2.759c.843 0 1.404 0 1.924-.157a3.65 3.65 0 001.257-.673c.42-.345.731-.812 1.198-1.513l.056-.084 1.392-2.087.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09-1.392-2.087-.056-.084c-.467-.7-.778-1.168-1.198-1.513a3.65 3.65 0 00-1.257-.673z" clipRule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div>
                                                12{/* */}
                                                skills
                                            </div>
                                        </div>
                                        <p className="interview-guides_openingStat__2ic16">
                                            <span className="interview-guides_icon__ciEI4">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 11.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 8.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M7.21 3.993c1.004-.717 1.506-1.076 2.053-1.214a3 3 0 011.474 0c.547.138 1.05.497 2.053 1.214l2.387 1.705c.615.44.922.659 1.031.93a1 1 0 010 .743c-.109.272-.416.492-1.03.931l-2.388 1.705c-1.004.717-1.506 1.076-2.053 1.214a3 3 0 01-1.474 0c-.547-.138-1.05-.497-2.053-1.214L4.823 8.302c-.615-.44-.922-.659-1.031-.93a1 1 0 010-.743c.109-.272.416-.492 1.03-.931L7.21 3.993z" />
                                                </svg>
                                            </span>
                                            3{/* */}
                                            interviews
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a className="interview-guides_opening__kmi1q" href="interview-guides/trvmo4aa/customer-success-manager.html">
                                <div className="interview-guides_coverContainer__DdaPs" style={{ backgroundImage: 'url(_/assets/covers/cover_8.html)' }} />
                                <div className="interview-guides_contentContainer__13OC2">
                                    <h2 className="interview-guides_openingHeader__2qgJe">Customer Success Manager</h2>
                                    <p className="interview-guides_openingDesc__lnhrH">
                                        This interview flow for hiring a Customer Success Manager focuses on evaluating the job-related technical and soft skills of CSM candidates. It has three stages and includes evaluation guidelines
                                        so that anyone can use it.
                                    </p>
                                    <div className="interview-guides_openingStats__tvOQM">
                                        <div className="interview-guides_openingStat__2ic16">
                                            <div>
                                                <span className="interview-guides_icon__ciEI4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path fillRule="evenodd" d="M10.431 4.65c.98 0 1.334.007 1.646.101.296.09.57.237.81.434.252.207.454.498.997 1.313l1.392 2.088c.456.684.584.892.633 1.1.05.206.05.422 0 .629-.05.207-.177.415-.633 1.1l-1.392 2.087c-.543.815-.745 1.106-.997 1.313a2.352 2.352 0 01-.81.434c-.312.094-.666.101-1.646.101H8.715l2.143-3.214.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09L8.715 4.65h1.716zm-3.278 0c-.523.003-.931.011-1.27.039-.46.037-.736.108-.95.217a2.35 2.35 0 00-1.027 1.027c-.109.214-.18.49-.217.95C3.65 7.35 3.65 7.949 3.65 8.8v2.4c0 .85 0 1.45.039 1.918.037.46.108.735.217.949a2.35 2.35 0 001.027 1.027c.214.109.49.18.95.217.339.028.747.036 1.27.038l2.623-3.934c.456-.684.584-.893.633-1.1.05-.207.05-.423 0-.63-.05-.207-.177-.415-.633-1.1L7.153 4.652zm5.302-1.143c-.52-.157-1.081-.157-1.923-.157H7.772c-.816 0-1.469 0-1.995.043-.54.044-1.006.137-1.434.355a3.65 3.65 0 00-1.595 1.595c-.218.428-.31.893-.355 1.434-.043.526-.043 1.179-.043 1.995v2.456c0 .816 0 1.469.043 1.995.044.54.137 1.006.355 1.434a3.65 3.65 0 001.595 1.595c.428.218.893.31 1.434.355.526.043 1.179.043 1.995.043h2.759c.843 0 1.404 0 1.924-.157a3.65 3.65 0 001.257-.673c.42-.345.731-.812 1.198-1.513l.056-.084 1.392-2.087.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09-1.392-2.087-.056-.084c-.467-.7-.778-1.168-1.198-1.513a3.65 3.65 0 00-1.257-.673z" clipRule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div>
                                                7{/* */}
                                                skills
                                            </div>
                                        </div>
                                        <p className="interview-guides_openingStat__2ic16">
                                            <span className="interview-guides_icon__ciEI4">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 11.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 8.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M7.21 3.993c1.004-.717 1.506-1.076 2.053-1.214a3 3 0 011.474 0c.547.138 1.05.497 2.053 1.214l2.387 1.705c.615.44.922.659 1.031.93a1 1 0 010 .743c-.109.272-.416.492-1.03.931l-2.388 1.705c-1.004.717-1.506 1.076-2.053 1.214a3 3 0 01-1.474 0c-.547-.138-1.05-.497-2.053-1.214L4.823 8.302c-.615-.44-.922-.659-1.031-.93a1 1 0 010-.743c.109-.272.416-.492 1.03-.931L7.21 3.993z" />
                                                </svg>
                                            </span>
                                            3{/* */}
                                            interviews
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a className="interview-guides_opening__kmi1q" href="interview-guides/erk99d4p/talent-acquisition-specialist.html">
                                <div className="interview-guides_coverContainer__DdaPs" style={{ backgroundImage: 'url(_/assets/covers/cover_6.html)' }} />
                                <div className="interview-guides_contentContainer__13OC2">
                                    <h2 className="interview-guides_openingHeader__2qgJe">Talent Acquisition Specialist</h2>
                                    <p className="interview-guides_openingDesc__lnhrH">
                                        This is a 2-stage interview flow for hiring a Talent Acquisition Specialist. It focuses on evaluating the important technical skills, as well as culture fit with the organization and the team that
                                        they are being considered for.
                                    </p>
                                    <div className="interview-guides_openingStats__tvOQM">
                                        <div className="interview-guides_openingStat__2ic16">
                                            <div>
                                                <span className="interview-guides_icon__ciEI4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path fillRule="evenodd" d="M10.431 4.65c.98 0 1.334.007 1.646.101.296.09.57.237.81.434.252.207.454.498.997 1.313l1.392 2.088c.456.684.584.892.633 1.1.05.206.05.422 0 .629-.05.207-.177.415-.633 1.1l-1.392 2.087c-.543.815-.745 1.106-.997 1.313a2.352 2.352 0 01-.81.434c-.312.094-.666.101-1.646.101H8.715l2.143-3.214.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09L8.715 4.65h1.716zm-3.278 0c-.523.003-.931.011-1.27.039-.46.037-.736.108-.95.217a2.35 2.35 0 00-1.027 1.027c-.109.214-.18.49-.217.95C3.65 7.35 3.65 7.949 3.65 8.8v2.4c0 .85 0 1.45.039 1.918.037.46.108.735.217.949a2.35 2.35 0 001.027 1.027c.214.109.49.18.95.217.339.028.747.036 1.27.038l2.623-3.934c.456-.684.584-.893.633-1.1.05-.207.05-.423 0-.63-.05-.207-.177-.415-.633-1.1L7.153 4.652zm5.302-1.143c-.52-.157-1.081-.157-1.923-.157H7.772c-.816 0-1.469 0-1.995.043-.54.044-1.006.137-1.434.355a3.65 3.65 0 00-1.595 1.595c-.218.428-.31.893-.355 1.434-.043.526-.043 1.179-.043 1.995v2.456c0 .816 0 1.469.043 1.995.044.54.137 1.006.355 1.434a3.65 3.65 0 001.595 1.595c.428.218.893.31 1.434.355.526.043 1.179.043 1.995.043h2.759c.843 0 1.404 0 1.924-.157a3.65 3.65 0 001.257-.673c.42-.345.731-.812 1.198-1.513l.056-.084 1.392-2.087.06-.09c.369-.553.645-.967.755-1.428a2.65 2.65 0 000-1.236c-.11-.461-.386-.875-.755-1.428l-.06-.09-1.392-2.087-.056-.084c-.467-.7-.778-1.168-1.198-1.513a3.65 3.65 0 00-1.257-.673z" clipRule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div>
                                                8{/* */}
                                                skills
                                            </div>
                                        </div>
                                        <p className="interview-guides_openingStat__2ic16">
                                            <span className="interview-guides_icon__ciEI4">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 11.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M5.188 8.438l-.438.312v0c-.548.392-.823.588-.934.823a1 1 0 000 .854c.111.235.386.431.934.823l2.46 1.757c1.004.717 1.506 1.076 2.053 1.214a3 3 0 001.474 0c.547-.138 1.05-.497 2.053-1.214l2.46-1.757c.549-.392.823-.588.934-.823a1 1 0 000-.854c-.111-.235-.386-.431-.934-.823v0l-.438-.313" />
                                                    <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1.3" d="M7.21 3.993c1.004-.717 1.506-1.076 2.053-1.214a3 3 0 011.474 0c.547.138 1.05.497 2.053 1.214l2.387 1.705c.615.44.922.659 1.031.93a1 1 0 010 .743c-.109.272-.416.492-1.03.931l-2.388 1.705c-1.004.717-1.506 1.076-2.053 1.214a3 3 0 01-1.474 0c-.547-.138-1.05-.497-2.053-1.214L4.823 8.302c-.615-.44-.922-.659-1.031-.93a1 1 0 010-.743c.109-.272.416-.492 1.03-.931L7.21 3.993z" />
                                                </svg>
                                            </span>
                                            2{/* */}
                                            interviews
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </ol>
                    </div>
                </div>
            </main>
        </>
    )
}

export default InterviewGuides;