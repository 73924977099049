import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {

    const [activeMenu, setActiveMenu] = useState(false);
    const [activeLink, setActiveLink] = useState('home');

    const handleChange = (item) => {
        setActiveLink(item);

        if(activeLink){
            toggleMenu();
        }
    }

    const toggleMenu = () => {
        setActiveMenu(!activeMenu)
    }
    return (
        <>
            <nav className="header_header__pKEQL max-width">
                <div className="header_desktopMenu__8ZGNu">
                    <div className="header_linkContainer__O5r_R">
                        <Link className="header_logo__0SslT" to="/" style={{ width: 90 }} onClick={() => handleChange('home')}>
                            <span className="images">
                                <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%', top: -15 }}>
                                    <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                        <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2768%27%20height=%2768%27/%3e" />
                                    </span>
                                    <img alt="logo" srcSet="_next/static/media/logo.ae1406cb.svg 1x, _next/static/media/logo.ae1406cb.svg 2x" src="_next/static/media/logo.ae1406cb.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                </span>
                            </span>
                        </Link>
                        {/* <Link className="header_navlinks__k2f6F" to="/interviewGuides">Interview guides</Link> */}
                        <Link className={`header_navlinks__k2f6F ${activeLink === 'home' ? 'activeLink' : ''}`} to="/" onClick={() => handleChange('home')}>Home</Link>
                        <Link className={`header_navlinks__k2f6F ${activeLink === 'price' ? 'activeLink' : ''}`} to="/price" onClick={() => handleChange('price')}>Pricing</Link>
                        <Link className={`header_navlinks__k2f6F ${activeLink === 'company' ? 'activeLink' : ''}`} to="/company" onClick={() => handleChange('company')}>Company</Link>
                    </div>
                    <div className="header_form__Zc__e">
                        <a className="header_link__jujke" target="_blank" href="https://jobgo.ai">Sign in</a>
                        <a className="header_button__af_om" target="_blank" href="https://jobgo.ai">Sign up</a>
                    </div>
                </div>
                <div className="header_mobileMenu__GyS2_">
                    <div className={`header_fixedMenu__EUipp ${activeMenu ? 'header_fixedMenuOpen__iHCC3' : ''} `}>
                        <div className="header_fixedMenuHeader__VDPZb">
                            <div className="header_fixedClosedIcon__ink1x" onClick={toggleMenu}>
                                <div className="header_hamburger__kYfxY">
                                    <span className="images">
                                        <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                            <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                                <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2724%27%20height=%2724%27/%3e" />
                                            </span>
                                            <img alt="Close menu icon" srcSet="_next/static/media/x.f7fdaec3.svg 1x, _next/static/media/x.f7fdaec3.svg 2x" src="_next/static/media/x.f7fdaec3.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="header_fixedNavContainer__nCDxQ">
                                <Link className={`header_fixedNavLink__UNMw2 ${activeLink === 'home' ? 'activeLink' : ''}`} to="/" onClick={() => handleChange('home')}>Home</Link>
                                <Link className={`header_fixedNavLink__UNMw2 ${activeLink === 'price' ? 'activeLink' : ''}`} to="/price" onClick={() => handleChange('price')}>Pricing</Link>
                                <Link className={`header_fixedNavLink__UNMw2 ${activeLink === 'company' ? 'activeLink' : ''}`} to="/company" onClick={() => handleChange('company')}>Company</Link>
                                {/* <Link className="header_fixedNavLink__UNMw2" to="/interviewGuides">Interview guides</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="header_mobileTopMenu__h72VL">
                        <Link className="header_logo__0SslT" to="/" style={{ width: 90 }} onClick={() => handleChange('home')}>
                            <span className="images">
                                <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%', top: -15 }}>
                                    <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                        <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2768%27%20height=%2768%27/%3e" />
                                    </span>
                                    <img alt="logo" srcSet="_next/static/media/logo.ae1406cb.svg 1x, _next/static/media/logo.ae1406cb.svg 2x" src="_next/static/media/logo.ae1406cb.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                </span>
                            </span>
                        </Link>
                        <div className="header_mobileRightContainer__YLgzl">
                            <a className="header_mobileLogin__jw32c" href="https://jobgo.ai">Sign in</a>
                            <button type="button" className="header_mobileButton__aYaAW" href="https://jobgo.ai">Sign up</button>
                            <div onClick={toggleMenu}>
                                <div className="header_hamburger__kYfxY">
                                    <span className="images">
                                        <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                            <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                                <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2724%27%20height=%2724%27/%3e" />
                                            </span>
                                            <img alt="Expand menu icon" srcSet="_next/static/media/menu.9de14fb7.svg 1x, _next/static/media/menu.9de14fb7.svg 2x" src="_next/static/media/menu.9de14fb7.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header;